<template>
  <div>
    <sgv-input-text v-model="search" label="ค้นหา"></sgv-input-text>

    <ul class="list-unstyled">
      <li v-for="item in items" :key="item.id">
        <div class="row">
          <div class="col-4">
            <fa
              icon="check"
              class="text-success pointer mr-1"
              @click="addChild(item)">
            </fa>&nbsp;
            {{item.code}}
          </div>

          <div class="col-4 text-center">
            <span class="text-info" v-if="item.billedAt">
              {{item.billedAt | datetime}}
            </span>
            <span class="text-info" v-else>
              --/--/----
            </span>
          </div>

          <div class="col-4 text-right">
            <span class="text-success">
              {{item.totalPrice | comma(-1)}}
            </span>
            บาท
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import {
  LIST_ORDER_AVAILABLE_INVOICE,
  CREATE_LINK
} from './graph'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      items: [],
      search: ''
    }
  },
  methods: {
    getList: debounce(function (filter) {
      this.$apollo.query({
        query: LIST_ORDER_AVAILABLE_INVOICE(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          q: filter
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.items = res.data.docs
      })
      .catch(() => {
        this.items = []
      })
    }, 150),
    addChild (paper) {
      this.$apollo.mutate({
        mutation: CREATE_LINK(this.templateType),
        variables: {
          docType: this.docType,
          parentId: this.docId,
          childId: paper.id
        }
      })
      .then(() => {
        this.refetchData(this.search)
        this.emitUpdated()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    emitUpdated () {
      this.$emit('updated', null)
    },
    refetchData () {
      if (this.search) {
        this.getList({
          limit: 10,
          params: {
            search: this.search
          }
        })
      } else {
        this.getList({
          limit: 10
        })
      }
    }
  },
  watch: {
    search: {
      handler () {
        this.refetchData()
      },
      immediate: true
    }
  }
}
</script>

<style lang="css" scoped>
</style>
