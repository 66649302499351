<template>
  <div class="mt-3">
    <sgv-table
      :items="items"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options"
      :toolbars="toolbars">

      <DetailOrderInvoiceAvailable
        ref="invoice"
        slot="option"
        v-show="options.toolbar === 'invoice' && !formData.approvedAt"
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        @updated="fetchData">
      </DetailOrderInvoiceAvailable>

      <DetailOrderCreditNoteAvailable
        ref="creditNote"
        slot="option"
        v-show="options.toolbar === 'creditNote' && isCreditNote && !formData.approvedAt"
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        @updated="fetchData"
        @isItems="isCreditNote = $event">
      </DetailOrderCreditNoteAvailable>

      <DetailOrderDebitNoteAvailable
        ref="debitNote"
        slot="option"
        v-show="options.toolbar === 'debitNote' && isDebitNote && !formData.approvedAt"
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        @updated="fetchData"
        @isItems="isDebitNote = $event">
      </DetailOrderDebitNoteAvailable>

      <template slot-scope="row">
        <DetailOrderInvoice
          v-if="row.item.tag === 'invoice'"
          :item="row.item"
          :canDelete="!formData.approvedAt"
          @delete="deleteChild">
        </DetailOrderInvoice>

        <DetailOrderCreditNote
          v-else-if="row.item.tag === 'creditNote'"
          :item="row.item"
          :canDelete="!formData.approvedAt"
          @delete="deleteChild">
        </DetailOrderCreditNote>

        <DetailOrderDebitNote
          v-else-if="row.item.tag === 'debitNote'"
          :item="row.item"
          :canDelete="!formData.approvedAt"
          @delete="deleteChild">
        </DetailOrderDebitNote>
      </template>

      <div class="" slot="action">
        <sgv-input-text
          class="my-md-0"
          disabled
          :value="totalPriceSum">
        </sgv-input-text>
      </div>
    </sgv-table>
  </div>
</template>

<script>
import round from 'lodash/round'
import DetailOrderInvoice from './DetailOrderInvoice.vue'
import DetailOrderCreditNote from './DetailOrderCreditNote.vue'
import DetailOrderDebitNote from './DetailOrderDebitNote.vue'
import DetailOrderInvoiceAvailable from './DetailOrderInvoiceAvailable.vue'
import DetailOrderCreditNoteAvailable from './DetailOrderCreditNoteAvailable.vue'
import DetailOrderDebitNoteAvailable from './DetailOrderDebitNoteAvailable.vue'
import {
  LIST_ORDER,
  DESTROY_LINK
} from './graph'


export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['id', 'approvedAt', 'totalPrice'],
        column: null,
        search: null,
        toolbar: null,
      },
      items: [],
      isCreditNote: false,
      isDebitNote: false
    }
  },
  computed: {
    headers () {
      return [
        {text: 'รหัส', value: 'id'},
        {text: 'วันที่', value: 'approvedAt', class: ['text-center']},
        {text: `ราคา`, value: 'totalPrice'}
      ]
    },
    toolbars () {
      if (this.formData.approvedAt) return []

      const arr = [
        {value: 'invoice', icon: 'truck', class: 'text-warning'}
      ]

      if (this.isCreditNote) {
        arr.push({value: 'creditNote', icon: 'file-download', class: 'text-success'})
      }

      if (this.isDebitNote) {
        arr.push({value: 'debitNote', icon: 'dollar-sign', class: 'text-danger'})
      }

      return arr
    },
    totalPriceSum () {
      const total = this.items.reduce((t,v) => {
        if (v.tag === 'invoice') {
          t += v.totalPrice
        } else if (v.tag === 'creditNote') {
          t -= v.usable
        } else if (v.tag === 'debitNote') {
          t += v.usable
        }
        return round(t, 2)
      }, 0)
      return 'ยอดรวม: ' + total
    },
  },
  methods: {
    fetchData () {
      this.$apollo.query({
        query: LIST_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        const creditNotes = []
        const debitNotes = []
        let total = res.data.invoices.reduce((t,v) => {
          t += v.totalPrice
          return round(t, 2)
        }, 0)

        for (let debitNote of res.data.debitNotes) {
          debitNote.usable = debitNote.remaining
          total += round(debitNote.remaining, 2)
          debitNotes.push(debitNote)
        }

        for (let creditNote of res.data.creditNotes) {
          const delta = round(creditNote.remaining, 2)

          if (total - delta >= 0) {
            creditNote.usable = creditNote.remaining
          } else {
            creditNote.usable = total
          }

          total -= round(creditNote.remaining, 2)

          creditNotes.push(creditNote)
        }

        this.creditNotes = creditNotes
        this.debitNotes = debitNotes
        this.items = [
          ...res.data.invoices.map(v => {
            v.tag = 'invoice'
            return v
          }),
          ...debitNotes.map(v => {
            v.tag = 'debitNote'
            return v
          }),
          ...creditNotes.map(v => {
            v.tag = 'creditNote'
            return v
          }),
        ]
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    deleteChild (childId) {
      this.$apollo.mutate({
        mutation: DESTROY_LINK(this.templateType),
        variables: {
          docType: this.docType,
          parentId: this.docId,
          childId: childId
        }
      })
      .then(() => {
        this.$refs.invoice.refetchData()
        this.$refs.creditNote.refetchData()
        this.$refs.debitNote.refetchData()
        this.fetchData()
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
  },
  created () {
    this.fetchData()
  },
  components: {
    DetailOrderInvoice,
    DetailOrderCreditNote,
    DetailOrderDebitNote,
    DetailOrderInvoiceAvailable,
    DetailOrderCreditNoteAvailable,
    DetailOrderDebitNoteAvailable,
  }
}
</script>

<style lang="css" scoped>
</style>
